@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root {
  --primary: #4ea8d2;
  --secondary: #ffffff; 
  --tertiary: #222823; 
  --quaternary: #eeeeee; /* background light gray e5e5e5 f2f2f2 eeeeee */
  --quinary: #fb8500; /* fb8500 ef7f11*/
  --senary: #fdf8e1;
  --septenary: #ffc857;
  --octonary: #22577a; /* navbar colour 1a759f 124559 3c6e71 005f73 22577a */
  --nonary: #E9f5db;
  --denary: #003554; /* for text h2 p */
}

body {
  margin: 0;
  /* padding: 20px; */
  font-family: 'Poppins', sans-serif;
  font-size: 20px !important;
  background: var(--secondary);
}

.custom-navbar {
  background-color: var(--octonary); 
}

/* page content */
/* main { */
  /* max-width: 1200px; */
  /* margin: 40px auto; */
  /* background-color: var(--denary); */
  /* background: linear-gradient(to bottom, var(--denary), white);
  background-color: var(--denary);
  border-top-left-radius: 20% 20%; */
/* } */

main h2, p {
  
  width: 85%;
  margin: 0 auto; /* Center the elements */
}


h2 {
  /* color: var(--tertiary) !important; */
  color: var(--denary) !important;
  font-size: 1.8em !important;
} 

p {
  /* color: var(--tertiary);   */
  color: var(--denary);
  /* text-align: justify; */
}

.home-div {
  padding-top: 80px; /* Adjust the value as needed */
  padding-bottom: 10px;
  background-color: var(--secondary);
  text-align: center;
}

.talents-div {
  width: 85%;
  padding-top: 60px;
  padding-bottom: 10px;
  margin: 25px auto;
  background: linear-gradient(to bottom, var(--quaternary), white); 
  overflow: hidden;
  border-radius: 40px;
}

.jobs-div {
  padding-top: 60px; /* Adjust the value as needed */
  padding-bottom: 10px;
  background: linear-gradient(to bottom, var(--quaternary), white);
  text-align: center;
}

.contact-div {
  width: 85%;
  padding-top: 60px;
  padding-bottom: 10px;
  margin: 80px auto;
  /* border: 1px solid var(--quaternary); */
  /* background-color: var(--quaternary); */
  background: linear-gradient(to bottom, var(--quaternary), white); 
  overflow: hidden;
  border-radius: 80px;
}

.talents-sub-div {
  padding-top: 0px; /* Adjust the value as needed */
  padding-bottom: 50px;
  /* background: linear-gradient(to bottom, var(--octonary), white); */
  background-color: var(--secondary);
  /* border-top-left-radius: 20% 20%; */
  /* text-align: center; */
}

.jobs-sub-div {
  padding-top: 0px; /* Adjust the value as needed */
  padding-bottom: 50px;
  /* background: linear-gradient(to bottom, var(--octonary), white); */
  background-color: var(--secondary);
  /* border-top-left-radius: 20% 20%; */
  /* text-align: center; */
}

.not-found-div {
  padding-top: 80px; /* Adjust the value as needed */
  padding-bottom: 10px;
  background-color: var(--secondary);
  text-align: center;
}

.footer-div {
  padding-top: 60px; /* Adjust the value as needed */
  padding-bottom: 10px;
  background-color: var(--octonary);
  /* background: linear-gradient(to bottom, var(--denary) 0%, white 50%, white 100%); */
  /* background: linear-gradient(to bottom, var(--quinary) 0%, white 100%); */


  text-align: center;
}

.footer-div p{
  color: var(--secondary);
  font-size: 16px;
}

.footer-link{
  color: var(--secondary);
  margin-right: 10px;
  margin-left: 10px;
  text-decoration: none;
  font-size: 16px;
}

/* logo */
.logo {
  width: 200px;
  height: auto;
}

.section-card {
  width: 85%;
  margin: 20px auto;
  overflow: hidden;
}

.section-content {
  display: flex;
  flex-direction: row;
}

.section-text {
  text-align: left;
}

.about-image,
.section-text {
  padding-left: 3%;
  padding-right: 3%; 
  padding-bottom: 3%;
  flex: 1;
}

.about-image img {
  width: 100%; /* Update this line to make the image take 100% of its container */
  height: auto;
}

/* Align image to the right */
.section-card .about-image.right {
  order: 2;
}

@media (max-width: 767px) {
  .section-content {
      flex-direction: column;
  }

  .section-card .about-image.right {
      order: 1;
  }
}

button {
  float: right;
}

.hire-steps-div {
  width: 88%;
  margin: auto;
  margin-bottom: 50px;
  overflow: hidden;
}


.hire-steps-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid var(--quinary); /* Orange border with thickness */
  border-radius: 40px; /* Border radius */
  padding: 20px; /* Add padding for spacing */
  margin: 50px auto; /* Center horizontally with margin */
  max-width: 400px; /* Limit the width */
}

.talents-sub-div p {
  text-align: left;
  
}

.jobs-sub-div p {
  text-align: left;
}